import React from "react"
import CreateDFXModelForm from "../components/dfxModel/CreateDFXModelForm"
import Layout from "../components/layout"

const DFXForm = () => {
  return (
    <Layout>
      <CreateDFXModelForm />
    </Layout>
  )
}

export default DFXForm
